import badgeService from '@/services/badge-service'
import missionService from '@/services/mission-service'
import emitter from '@/utils/emitter'
import store from '@/store'
export default {
    isLastTask(mission, taskId) {
        let index = 0
        for (let task of mission.tasks) {
            if (task.id === taskId) {
                break
            }
            index++
        }
        return index === mission.tasks.length - 1
    },
    async updateTask(mission, task, pointsWon, finished, router) {
        const notPreviewModeAndTaskCompleted = !store.getters.isPreviewMode && mission && task && !task.completed
        let badge = null
        if (notPreviewModeAndTaskCompleted) {
            await missionService.updateTask(mission, task, pointsWon, finished)
            store.dispatch('refreshMissions')

            if (this.isLastTask(mission, task.id) && store.getters.isBadgesEnabled) {
                try {
                    badge = await badgeService.awardBadgeForMission(mission.id)
                } catch (error) {
                    console.log(error)
                }
            }

            if (this.isLastTask(mission, task.id)) {
                missionService.awardCertificate(mission.id)
            }
            store.dispatch('refreshCurrentUser')
        }
        const shouldNotRedirect = ['ACTION_LIBRARY', 'ACTION_ARENA'].includes(task?.taskType?.alias)
        emitter.emit('openTaskEndModal', {
            task,
            pointsWon,
            isLastTask: this.isLastTask(mission, task.id),
            missionId: mission.id,
            shouldNotRedirect,
            isPlayAgain: false,
            badge: badge?.data
        })
        if (!router) {
            return
        }
        const taskLength = mission.tasks.length
        const missionExists = mission && mission.id
        if ((missionExists) &&
            (taskLength > 1 || (!store.getters.skipMissionRouteOnSingleTask && taskLength === 1))) {
            router.push({ path: `/missions/${mission.id}` })
        } else {
            router.push({ path: `/home` })
        }
    }
}
